import { ChainId } from '@pulsex/chains'

export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const BLOCKS_CLIENT = 'https://graph.pulsechain.com/subgraphs/name/pulsechain/blocks'
export const BLOCKS_CLIENT_TESTNET = 'https://graph.v4.testnet.pulsechain.com/subgraphs/name/pulsechain/blocks'
export const GRAPH_HEALTH = 'https://health.graph.pulsechain.com'
export const QUOTING_API = 'https://routing-v2.apis.pulsex.com/quote'
export const POOLS_API = 'https://routing-v2.apis.pulsex.com/pools'
export const PULSECHAIN_BRIDGE_URL = 'https://pulsebridge.kedz.eu'

export const BLOCKS_CLIENT_WITH_CHAIN = {
  [ChainId.PULSECHAIN]: BLOCKS_CLIENT,
  [ChainId.PULSECHAIN_TESTNET]: BLOCKS_CLIENT_TESTNET,
}
